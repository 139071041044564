#footer {
  .no-margin {
    margin: 0;
  }
  .dd-contact-left-column {
    direction: rtl;
    padding: 0 21px;
    margin-top: -5px;
    color: #FFF;
    .need-info {
      line-height: 1.5;
    }
  }
  .dd-contact-center-column {
    padding: 15px 34px;
    p {
      border-right: 2px solid #FFF;
      border-left: 2px solid #FFF;
      margin: 0px -28px;
      padding: 0 29px;


    }
  }
  .dd-contact-right-column {
    padding: 6px 31px;
    .dd-contact-email {
      color: #b0b0b0;
      font-size: 14px;
      line-height: 0.1;
    }
  }
  .dd-contact-phone-img {
    padding: 7px;
    background-color: #FFFFFF;
    -webkit-filter: invert(100%);
    filter: invert(100%);
    width: 60px;
    float: left;
    &::after{
      clear:both;
    }
  }
  .dd-acts {
    .dd-acts-body {
      padding: 12px;
      background-image: url('../images/red-background.jpg');
      background-size: cover;
      .btn-wrapper {
        margin: 0;
      }
    }
  }
  .dd-book {
    padding: 17px 0;
    font-size: 24px;
  }
  .book-us-online{
    position: relative;
    background-color: #d7002b;
    text-align: center;
    border-radius: 50px;
    padding: 1px 30px 1px 1px;
  }
  .dd-row-contact {
    margin-top: 50px;
  }
  .right-border {
    border-right: 1px solid #FFFFFF;
    height: 110px;
    margin-top: 0;
  }
  .dd-footer {
    color: #FFFFFF;
    text-align: center;
  }
  .footerbottom {
    background-image: url(../images/bg-footer-schuinestrepen.svg);
    background-repeat: no-repeat;
    background-size: contain;
    width: 100%;
    background-position-y: 20px;
    .scroll {
      margin-top: 70px;
      overflow: hidden;
      padding: 40px 0;
      font-size: 8px;
      &:hover {
        color: #000;
        transition: 0.3s ease;
        &::after {
          animation-name: fadeOutRight;
          animation-iteration-count: infinite;
          animation-duration: 1s;
          color: #000;
          transition: 0.3s ease;
        }
      }
      &:hover {
        color: #000;
      }
    }
    .scrolltop {
      text-align: center;

    }
    .scroll-up {
      background-color: #d7002b;
      border-radius: 50%;
      width: 30px;
      height: 30px;
      color: #fff;
      cursor: pointer;
      transition: 0.3s ease;
      margin-top: 50px;
      position: relative;

    }
    .page-scroll-btn {
      width: 80px;
      height: 80px;
      background-color: #d7002b;
      border-radius: 50px;
      margin-top: 9px;
      margin-left: 99px;

      position: relative;
      &:before {
        content: '';
        height: 60px;
        width: 60px;
        position: absolute;
        left: 10px;
        bottom: 10px;
        border-radius: 50%;
        border: 2px solid white;
      }
    }
    .swiper-container {
      width: 100%;
      height: 100%;
      cursor: pointer;
      .swiper-wrapper {
        width: 100%;
        height: 400px;
        .swiper-slide .swiper-slide-active {
          position: relative;
          text-align: center;
          font-size: 18px;
          background: #fff;
          /* Center slide text vertically */
          display: -webkit-box;
          display: -ms-flexbox;
          display: -webkit-flex;
          display: flex;
          -webkit-box-pack: center;
          -ms-flex-pack: center;
          -webkit-justify-content: center;
          justify-content: center;
          -webkit-box-align: center;
          -ms-flex-align: center;
          -webkit-align-items: center;
          align-items: center;
        }
        .swiper-slide {
          background-size: cover;
          > a {
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            right: 0;
            height: 100%;
            width: 333px;
          }
        }
        .swiper-button-next .swiper-button-prev {
          color: #d7002b;
          transition: 0.3s ease;
          &:hover {
            transform: scale(1.2, 1.2);
            transform-origin: 0 0;
            transition: 0.3s ease;
          }
        }
        .swiper-button-next {
          margin-right: 20px;
        }
        .swiper-button-prev {
          margin-left: 20px;
        }
      }
    }
  }
}


/* ==========================================================================
Desktop/iPad PRO
========================================================================== */
@media screen and (max-width: 1200px) {
  #footer {
    .icon-book {
      .book-us-btn {
        display: none;
      }
    }
    .dd-footer {
      color: #FFFFFF;
      text-align: center;
      margin-bottom: 0;
      margin-top: 0;
    }
    .footer-container {
      .questions {
        margin-top: -4px;
      }
    }
    .dd-book {
      padding: 6px;
      font-size: 24px;
      width: 670px;
    }
    .btn-wrapper .dd-btn.secondary {
      background: #FFFFFF;
      color: #d7002b;
      text-decoration: none;
      float: right;
      transition: 0.3s ease;
      position: absolute;
      right: -4px;
      width: 101px;
      height: 55px;
      &::after{
        clear:both;
      }
    }
    .right-border {
      border-right: 1px solid #FFFFFF;
      height: 157px;
      margin-top: 0;
    }
    .dd-contact-left-column {
      direction: rtl;
      padding: 25px 30px;
    }
    .dd-phone-icon {
      width: 59px;
      margin-top: 7px;
    }
    .footerbottom{
      background-position-y: 30%;
      .page-scroll-btn {
        margin-left: 33px;
      }
    }
  }
}

/* ==========================================================================
Tablet
========================================================================== */
@media screen and (max-width: 992px) {
  #footer {
    position: relative;
    .dd-book {
      padding: 6px;
      font-size: 24px;
      width: 610px;
    }
    .footer-container {
      margin-bottom: 80px;
      margin-left: 30px;
      .phone-and-mail {
        margin-top: -72px;
        float: right;
      }
    }
    .contact-title-container {
      text-align: left;
      .questions {
        direction: ltr;
        font-weight: 300;
        margin: 0;
        margin-left: 4px;
      }
      .need-info {
        display: none;
        font-size: 3.7rem;
        margin-top: -44px;
      }
    }
    .dd-phone-icon {
      width: 60px;
      margin-right: 6px;
    }
    .dd-footer {
      background-color: #d7002b;
      padding: 34px;
      margin: 0;
    }
    .dd-contact-left-column {
      padding: 0;
    }
    .dd-contact-center-column {
      float: left;
      p{
        padding: 0 20px 0 0;
        border-left: none;
        text-align: left;
        width:350px;
        font-size: 15px;
      }
    }
    .dd-contact-right-column{
      padding: 2px 50px;
      &::after{
        clear:both;
      }
    }
    .footerbottom {
      background: none;
      .scroll{
        margin-top: 0;
      }
      .page-scroll-btn {
        margin: 0 auto;
        position: absolute;
        top: -41px;
      }
    }
  }
}

/* ==========================================================================
Mobile
========================================================================== */
@media screen and (max-width: 767px) {
  #footer{
    .footer-container {
      margin-bottom: 0;
    }
    .icon-book {
      padding: 7px 0;
      width: 66px;
      height: 47px;
      color: #d7002b;
      .book-us-btn {
        display: none;
      }
      &::before{
        width:25px;
        height:20px;
      }
    }
    .dd-book {
      font-size: 16px;
      float: left;
      width: 75%;
      height: 45px;
      margin-top: 2px;
      padding: 1px 19px;
      &::after{
        clear:both;

      }
    }
    .book-us-online {
      position: relative;
      background-color: #d7002b;
      text-align: center;
      border-radius: 50px;
      margin:0 0 30px 0;
    }
    .contact-title-container .need-info{
      font-size: 3.7rem;
      margin-top: 0;
    }
    .contact-us-container {
      margin-top: 18px;
    }
    .dd-contact-center-column {
      width: 100%;
      margin: 0 auto;
      p {
        padding: 0 20px 0 0;
        text-align: left;
        width: 283px;
        font-size: 15px;
        border: none;
      }
    }
    .dd-contact-right-column{
      float:none;
      width: 100%;
      .phone-and-mail {
        margin-top: -56px;
        margin-left: 27px;
        float: left;
      }
    }
    .dd-phone-icon {
      margin-top: 9px;
      float: initial;
      margin-left: -47px;
    }
    .footerbottom {
      background: none;
      .swiper-container .swiper-wrapper {
        height: auto;
        .swiper-slide {
          height: 400px;
          background-position: top center;
        }
      }
      .page-scroll-btn {
        position: relative;
        top: 30px;
      }
    }
  }
}