#possibilitys {
  padding-top: 50px;
  #slider {
    margin: 10px 0;
    .swiper-container {
      position: relative;
      width: 100%;
      height: 100%;
      -webkit-box-shadow: 8px 8px 30px -6px rgba(0,0,0,1);
      -moz-box-shadow: 8px 8px 30px -6px rgba(0,0,0,1);
      box-shadow: 8px 8px 30px -6px rgba(0,0,0,1);
    }
    .swiper-slide {
      text-align: center;
      font-size: 28px;
      background: transparent;
      color: #FFFFFF;
      cursor: pointer;
      display: flex;
      padding: 0 24px;
      p{
        font-family: Roboto, sans-serif;
        font-weight: 100;
        font-style: italic;
        line-height: 25px;
        font-size: 20px;
      }
    }
    .swiper-wrapper {
      padding: 17px 0 96px 0;
    }
    .testimonial-swiper-pagination {
      text-align: center;
      margin-top: 10px;
    }
    .swiper-pagination-bullet {
      background-color: #acadaf;
      margin: 0 3px;
    }
    .swiper-pagination-bullet-active {
      background-color: #d7002b;
    }
  }


  /*VIDEO PANEL */
  .video-panel{
    margin: 20px 0 0 0;
    position: relative;
    background-color: #484848;
    border-radius: 10px;
    box-shadow:2px 120px 388px 4px;
    -webkit-box-shadow:2px 120px 388px 4px;
    -moz-box-shadow:2px 120px 388px 4px;
    z-index: 10;
    img{
      position: relative;
    }
    .video-panel-book {
      position: absolute;
      right:110px;
      bottom:5px;
      z-index: 999;
    }
  }
  .video-wrapper{
    overflow:hidden;
    position: relative;
  }
  .inactive .video-placeholder {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: auto;
    margin: 0;
    padding: 0;
    z-index: 1;
    transition: 0.5s ease;
    background-image: url('../images/bg-introblok.jpg');
    background-size:cover;
    background-position: center;
    height:100%;
  }
  .active .video-placeholder {
    top: -100%;
    transition: 0.5s ease;
  }
  .videolink {
    text-decoration: none;
    color:white;
    position: absolute;
    left:20px;
    bottom:20px;
    z-index: 999;
    .playbutton {
      height: 35px;
      width: 35px;
    }
    span{
      font-size: 10px;
      top:5px;
      position: absolute;
      font-weight: bold;
      text-transform: uppercase;
      padding: 0 0 0 12px;
    }
    &:hover .playbutton{
      animation-name: tada;
      animation-duration: 1.5s;
      animation-iteration-count: 1;
    }
  }
  /*Testimonial slider */

  /*Photo impression slider */

  .image-slider-wrapper {
    margin: 0 0 -100px 0;
    .swiper-container {
      width: 100%;
      height: 100%;
    }
    .swiper-slide {
      text-align: center;
      font-size: 18px;
      /* Center slide text vertically */
      display: -webkit-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      -webkit-justify-content: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      -webkit-align-items: center;
      align-items: center;
    }
  }
  .control-slider-wrapper {
    width:600px;
    max-height:500px;
    margin: 0 auto;
    .swiper-container {
      width: 100%;
      height: 100%;
      .swiper-slide {
        max-width: 200px;
        .img-responsive {
          border: 6px solid #444444;

        }
        &.swiper-slide-next {
          transform: scale(1.2);
        }
      }
    }
  }


  // Scroll
  ::-webkit-scrollbar {
    width: 1em;
  }
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 2px rgba(0,0,0,0.3);
  }
  ::-webkit-scrollbar-thumb {
    background-color: #484848;

    border: 1px solid #d7002b;
    outline: 1px solid red;
  }

  /*Book-us-online & contact */
  .dd-contact-left-column {
    direction: rtl;
    padding: 0px 36px;
  }
  .dd-contact-center-column {
    padding: 16px 36px;
  }
  .dd-contact-right-column {
    padding: 16px 36px;
  }
  .dd-book {
    padding: 6px;
    font-size: 24px;
  }
  .book-us-online {
    background-color: #d7002b;
    text-align: center;
    border-radius: 50px;
    padding-top: 1px;
    margin-top: 42%;
  }
  .dd-row-contact {
    margin-top: 50px;
  }
  .dd-phone-icon{
    width: 80px;
  }
  .right-border {
    border-right: 1px solid #FFFFFF;
    height: 105px;
  }
}
.impression-header {
  h2 {
    background-color: #1f1f1f;
    padding:30px 0 30px 350px;
    margin: 0;
  }
}




/* ==========================================================================
Desktop/iPad PRO
========================================================================== */
@media screen and (max-width: 1200px) {
  .breadcrumb {
    font-size: 14px;
    top: 359px;
    left: 0;
    padding: 5px 0 0 25px;
    position: absolute;
    z-index: 99;
    width: 50%;
    background-color: transparent;
    color: #696464;
    font-weight: bold;
    letter-spacing: 1px;
  }
  #possibilitys {
    .possibilitys {
      padding: 0 40px;
    }
    .videolink {
      text-decoration: none;
      color: white;
      position: absolute;
      left: 10px;
      bottom: 10px;
      z-index: 1;
    }
    .video-panel .video-panel-book {
      position: absolute;
      right: 68px;
      z-index: 1;
    }
    .video-panel {
      box-shadow: none;
      position: relative;
      background-color: #484848;
      border-radius: 10px;
      height: 517px;
    }
    #slider {
      .swiper-slide {
        font-family: Roboto, sans-serif;
        font-weight: 100;
        text-align: center;
        font-size: 21px;
        background: transparent;
        color: #FFFFFF;
        cursor: pointer;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        padding: 0 24px;
      }
    }
    #project-gallery-nav {
      padding: 0 0 200px 0;
      .wrapper {
        margin: 0;
        left:25%;
        .nav-item{

        }
      }
    }
  }
}

/* ==========================================================================
Tablet
========================================================================== */
@media screen and (max-width: 992px) {
  .breadcrumb{
    display: none;
  }
  #possibilitys {
    .possibilitys {
      padding: 0 36px
    }
    .img-responsive {
      display: block;
      max-width: 100%;
      height: auto;
      width: 100%;
    }
    .videolink {
      left: 30px;
      bottom: 30px;
      span {
        font-size: 13px;
      }
      .playbutton {
        width: 50px;
        height: 50px;
      }
    }
    .video-panel .video-panel-book {
      position: absolute;
      right: 276px;
      bottom: 0;
      z-index: 1;
    }
    .video-panel {
      position: relative;
      background-color: #484848;
      border-radius: 10px;
      z-index: 1;
      margin-top: 40px;
    }
    .photo-impression-header {
      padding: 30px 0 30px 258px;
      margin: 0;
      span{
        float:right;
        color:red;
      }
    }
    #slider {
      .swiper-slide {
        font-family: Roboto, sans-serif;
        font-weight: 100;
        text-align: center;
        font-size: 21px;
        background: transparent;
        color: #FFFFFF;
        cursor: pointer;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        padding: 0 24px;
      }
    }
    #project-gallery-nav {
      padding: 0 0 200px 0;
      .wrapper {
        margin: 0;
        left:20%;
        .nav-item{

        }
      }
    }
    .control-slider-wrapper{
      width: 100%;

    }
  }
}

/* ==========================================================================
Mobile
========================================================================== */
@media screen and (max-width: 767px) {
  #possibilitys {
    .possibilitys {
      h1 {
        font-size: 35px;
      }
    }
    .videolink {
      left: 10px;
      bottom: 10px;
    }
    .video-panel {
      position: relative;
      background-color: #484848;
      border-radius: 10px;
      z-index: 1;
    }
    .btn-wrapper {
      text-align: center;
      .video-panel-book {
        z-index: 1;
        display: block;
        position: static;
      }
    }
    .photo-impression-header {
      padding: 30px 0 30px 46px;
      margin: 0;
    }
    #slider {
      .swiper-wrapper {
        .control-slider-wrapper {
          font-family: Roboto, sans-serif;
          font-weight: 100;
          text-align: center;
          font-size: 21px;
          background: transparent;
          color: #FFFFFF;
          cursor: pointer;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          padding: 0 24px;
          .img-responsive{
            border: 6px solid #444444;
            width: 100px;
          }
        }
      }
    }
    #project-gallery-nav {
      padding: 0 0 200px 0;
      .wrapper {
        width: 100%;
        margin: 0;
        left:0;
        .nav-item{

        }
      }
    }
  }
  .impression-header {
    display: none;
  }
}