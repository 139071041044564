/* ==========================================================================
Menu
========================================================================== */
#menu {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 9;
  background: transparent;
  transition: background 0.7s ease;
  padding: 10px 0;
  &.fixed {
    background: #000;
    transition: background 1.5s ease;
    animation-name: slideDownMenu;
    animation-duration: 0.5s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    z-index: 999;
  }
  .home{

  }
  // Mobile Menu
  #mobile-btn {
    display: none;
    cursor: pointer;
    z-index: 99999;
    width: 30px;
    float: right;
    margin: 0 200px;
    height: 70px;
    position: relative;
    transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
    span {
      display: block;
      position: absolute;
      height: 3px;
      border-radius: 30px;
      width: 100%;
      max-width: 100%;
      background: #FFF;
      opacity: 1;
      left:0;
      transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
      transition: .25s ease-in-out;
      -moz-transition: .25s ease-in-out;
      -o-transition: .25s ease-in-out;
      transition: .25s ease-in-out;
      &:nth-child(1) {
        top: 23px;
      }
      &:nth-child(2) {
        top: 33px;
      }
      &:nth-child(3) {
        top: 43px;
      }
    }
    &.open {
      span {
        &:nth-child(1) {
          top: 35px;
          transform: rotate(135deg);
          -moz-transform: rotate(135deg);
          -o-transform: rotate(135deg);
          transform: rotate(135deg);
        }
        &:nth-child(2) {
          opacity: 0;
          left: -60px;
        }
        &:nth-child(3) {
          top: 35px;
          transform: rotate(-135deg);
          -moz-transform: rotate(-135deg);
          -o-transform: rotate(-135deg);
          transform: rotate(-135deg);
        }
      }
    }
  }
  .wrapper {
      float: left;
      ul {
        list-style: none;
        position: relative;
        float:left;
        padding: 0;
        margin: 0;
        li {
          position: relative;
          float: left;
          margin:0;
          padding: 20px 10px 10px 10px;
          &.active > a {
            color: #d7002b;
          }
          &.fa {
            position: relative;
            filter: invert(100%);
            margin-top: 9px;
            a {
              position: absolute;
              left: 0;
              top: 0;
              bottom: 0;
              right: 0;
            }
          }
          a {
            color: #FFFFFF;
            font-size: 12px;
            text-transform: uppercase;
            font-weight: 600;
            line-height: 2.8;
            padding: 0 10px;
            display: block;
            text-shadow: 1px 1px #000;
          }
          a:hover {
            text-decoration: none;
            color: #d7002b;
          }
          ul {
            z-index: 999;
            display: none;
            position: absolute;
            top:55px;
            left:20px;
            width:100%;
            border-left: 2px solid #a1172b;
            border-bottom: 2px solid #242424;
            background-color: #181818;
            animation-name: bounceInDown;
            animation-duration: 1s;
            animation-iteration-count: 1;
            li {
              padding: 6px 0 6px 4px;
              display: block;
              float:none;
              a{
                padding:5px 2px 5px 2px;
                line-height: 1.8;
              }
            }
          }
          &:hover ul{
            display: block;
            text-decoration: none;
          }
        }
        &::after {
          content: '';
          clear: both;
          display: block;
        }
      }
    .btn-wrapper{
      .dd-btn.secondary {
        top: 0;
      }
    }
    &::after {
      content: '';
      clear: both;
      display: block;
    }
  }
  .dd-logo {
    max-width: 75%;
    margin-top: 10px;
  }
}

/* ==========================================================================
Desktop/iPad Pro
========================================================================== */
@media screen and (max-width: 1200px) {
  #menu {
    .btn-wrapper {
      .secondary {
        top: 10px ;
        right: -10px;
      }
    }
  }
}



/* ==========================================================================
Tablet
========================================================================== */
@media screen and (max-width: 992px) {
  #menu{
    #mobile-btn {
      display: block;
    }
    &.nav-up {
      top: -100px;
      transition: 0.3s ease;
      position: fixed;
      animation: none;
    }
    &.nav-down {
      top: 0;
      transition: 0.3s ease;
      position: fixed;
      animation: none;
    }
    .dd-logo {
      max-width: 38%;
    }
    .wrapper  {
      .fa-home{
        &::before{
          display: none;
        }
      }
      #bottom-menu {
        position: fixed;
        bottom: 0;
        right: 0;
        left: 0;
        top: 100%;
        background: rgba(0, 0, 0, 0.9);
        z-index: 9999;
        overflow: hidden;
        opacity: 1;
        transition: 0.3s ease;
        float: none;
        > ul {
          float: none;
          padding-top: 80px;
          display: block;
          background: transparent;
          margin: 0;
          > li {
            margin: 0;
            padding: 0;
            float: none;
            border-bottom: 1px solid #595959;
            display: none;
            &.show {
              animation-name: fadeInLeftBig;
              animation-duration: 1s;
              animation-iteration-count: 1;
            }
            > a {
              text-align: left;
              font-size: 18px;
              line-height: 60px;
              padding: 0 20px;
              color: #ececec;
              font-weight: 300;
              background: transparent;
            }
            &.has-childs a::after {
              content: '\f105';
              height: 60px;
              width: 60px;
              line-height: 60px;
              float: right;
              font-size: 32px;
              display: block;
              text-align: center;
            }
            ul {
              animation: none !important;
              display: block;
              height: auto;
              position: static;
              left: 0;
              top: 100%;
              bottom: 0;
              right: 0;
              background: rgba(0, 0, 0, 1.0);
              z-index: 9999;
              opacity: 1;
              transition: 0.5s ease;
              margin-bottom: 20px;
              padding-left: 20px;
              float: none;
              display: block;
              li {
                margin: 0;
                padding: 0;
                float: none;
                border-bottom: 1px solid rgba(111, 111, 111, 0.7);
                display: block;
                a {
                  text-align: left;
                  font-size: 14px;
                  line-height: 30px;
                  padding: 0 20px;
                  color: #fff;
                  background: transparent;
                  text-decoration: none;
                  display: block;
                }
                a::after {
                  display: none !important;
                  content: '';
                  height: 0;
                  width: 0;
                }
                &.has-childs > a::after {
                  display: block !important;
                  font: normal normal normal 14px/1 FontAwesome;
                  font-size: 32px;
                  content: '\f105';
                  height: 60px;
                  width: 60px;
                  line-height: 60px;
                  float: right;
                  text-align: center;
                }
                &.back {
                  display: block !important;
                  a {
                    text-align: right;
                    &::after {
                      display: none !important;
                      content: '';
                      width: 0;
                      height: 0;
                    }
                    &::before {
                      display: inline !important;
                      font: normal normal normal 14px/1 FontAwesome;
                      content: '\f104';
                      font-size: 24px;
                      margin: 0 15px;
                    }
                  }
                }

              }
            }
            ul.active {
              display: block;
              transition: 0.5s ease;
              top: 0;
              opacity: 1;
            }
            // Display none subitems
            ul{

            }
          }
        }
      }
      .btn-wrapper{
        .dd-btn.secondary {
          top: -60px;
          span{
            display: none;
          }
        }
      }
    }
    &.open {
      top: 0 !important;
      transition: 0.3s ease;

      .wrapper {
        display: block;
        #bottom-menu {
          display: block;
          top: 0;
          transition: 0.3s ease;
        }
      }
    }
  }
}

/* ==========================================================================
Mobile
========================================================================== */
@media screen and (max-width: 767px) {
  #menu, #subpage {
    background-image: none;
    height: 0;
    #mobile-btn {
      position: absolute;
      left: 39px;
      top: 5px;
    }
    .menubar {
      background-color: black;
      .dd-logo {
        max-width: 200px;
      }
      .btn-wrapper{
        .icon-book {
          margin-right: 30px;
        }
        .dd-btn.secondary {
          top: -52px;
          span{
            display: none;
          }
        }
      }
    }
  }
}

/* ==========================================================================
iPhone 5
========================================================================== */
@media screen and (max-width: 325px) {
  #menu, #subpage {
    background-image: none;
    height: 0;
    #mobile-btn {
      position: absolute;
      left: 4px;
      top: 5px;
    }
    .menubar {
      .dd-logo {
        max-width: 177px;
      }
      .btn-wrapper{
        .dd-btn.secondary {
          top: -52px;
          right: -23px;
        }
      }
    }
  }
}