.editor {
  margin: 40px 0;
  .re-icon {
    font-family: RedactorFont, sans-serif;
    background-color: #000;
  }
  .redactor-editor, .redactor-box {
    background: transparent;
  }
  h1, h2, h3, h4, h5, p, span, li, a {
    font-family: 'Roboto', sans-serif;
  }
  h1 {
    font-size: 42px;
  }
  h2 {
    font-size: 36px;
  }
  h3 {
    font-size: 29px;
  }
  h4 {
    font-size: 21px;
  }
  h5 {
    font-size: 18px;
  }
  p {
    font-size: 18px;
    line-height: 1.4;
  }
  &.white {
    h1, h2, h3, h4, h5, p, li, a {
      color: #fff;
    }
    &.re-icon a {
      color: #000;
    }
  }
  &.grey {
    h1, h2, h3, h4, h5, p, span, li, a {
      color: #cecece;
    }
  }
  &.intro {
    h1 {
      color: #fff;
      font-size: 36px;
      font-weight: 900;
      margin-top: 10px;
    }
    h2 {
      color: #cecece;
      font-weight: 300;
      font-size: 28px;
    }
    p {
      color: #cecece;
      font-size: 20px;
    }
  }
  &.light-font {
    h1{
      color: #FFFFFF;
      font-size: 36px;
      font-weight: 300;
    }
    h2 {
      color: #FFFFFF;
      font-size: 36px;
      font-weight: 500;
    }
    h3 {
      span {
        font-weight: 100;
      }
    }
    p {
      color: #FFFFFF;
      font-size: 17px;
      font-weight: 300;
      line-height: 1.1;
      span {
        font-size: 16px;
      }
    }
    a {
      color: #FFFFFF;
      font-size: 14px;
      font-weight: 500;
      &:hover {
        text-decoration: none;
        cursor: pointer;
      }
    }
  }
  &.possibilitys {
    h1 {
      font-size: 42px;
      font-weight: 700;
    }
    h2 {
      font-size: 36px;
    }
    h3 {
      font-size: 29px;
      color: #d7002b;
      font-weight: 300;
    }
    h4 {
      font-size: 24px;
    }
    h5 {
      font-size: 18px;
    }
    p {
      font-size: 18px;
      line-height: 1.4;
      font-weight: 100;
    }
  }
}

/* ==========================================================================
Desktop/iPad PRO
========================================================================== */
@media screen and (max-width: 1200px) {
  .editor {
    h3 {
      font-size: 24px;
    }
    &.light-font {
      p {
        color: #FFFFFF;
        font-size: 16px;
        font-weight: 300;
      }
    }
  }
}
/* ==========================================================================
Tablet
========================================================================== */
@media screen and (max-width: 992px) {
  .editor {
    h1 {
      font-size: 42px;
    }
    h2 {
      font-size: 36px;
    }
    h3 {
      font-size: 40px;
    }
    h4 {
      font-size: 21px;
    }
    h5 {
      font-size: 18px;
    }
    p {
      font-size: 18px;
      line-height: 1.4;
    }
    &.intro {
      h1 {
        color: #fff;
        font-size: 42px;
        font-weight: 900;
        margin-top: 10px;
      }
      h2 {
        color: #cecece;
        font-weight: 300;
        font-size: 36px;
      }
      p {
        color: #cecece;
        font-size: 25px;
      }
    }
    &.light-font {
      h1 {
        color: #FFFFFF;
        font-size: 36px;
        font-weight: 300;
      }
      h2 {
        color: #FFFFFF;
        font-size: 35px;
        font-weight: 500;
      }
      h3 {
        span {
          font-weight: 100;
        }
      }
      p {
        color: #FFFFFF;
        font-size: 22px;
        font-weight: 300;
        span {
          font-size: 20px;
        }
      }
      a {
        color: #FFFFFF;
        font-size: 16px;
        font-weight: 500;
        &:hover {
          text-decoration: none;
          cursor: pointer;
        }
      }
    }
  }
}

/* ==========================================================================
Mobile
========================================================================== */
@media screen and (max-width: 767px) {
  .editor {
    h1 {
      font-size: 42px;
    }
    h2 {
      font-size: 36px;
    }
    h3 {
      font-size: 35px;
    }
    h4 {
      font-size: 21px;
    }
    h5 {
      font-size: 18px;
    }
    p {
      font-size: 18px;
      line-height: 1.4;
    }
    &.intro {
      h1 {
        color: #fff;
        font-size: 30px;
        font-weight: 900;
        margin-top: 10px;
      }
      h2 {
        color: #cecece;
        font-weight: 300;
        font-size: 28px;
      }
      p {
        color: #cecece;
        font-size: 21px;
      }
    }
    &.light-font {
      h1 {
        color: #FFFFFF;
        font-size: 36px;
        font-weight: 300;
      }
      h2 {
        color: #FFFFFF;
        font-size: 28px;
        font-weight: 500;
      }
      h3 {
        span {
          font-weight: 100;
        }
      }
      p {
        color: #FFFFFF;
        font-size: 20px;
        font-weight: 300;
        span {
          font-size: 20px;
        }
      }
      a {
        color: #FFFFFF;
        font-size: 20px;
        font-weight: 500;
        &:hover {
          text-decoration: none;
          cursor: pointer;
        }
      }
    }
  }
}