/* ==========================================================================
Buttons
========================================================================== */
.btn-wrapper {
  margin: 15px 0;
  .secondary.icon-book {
    color: #d7002b;
  }
  .dd-btn {
    color: #fff;
    padding: 10px;
    border-radius: 50px;
    margin: 0 15px 15px 0;
    transition: 0.3s ease;
    text-decoration: none;
    float: left;
    &:hover {
      color: #fff;
    }
    &.primary {
      background: #d7002b;
      text-decoration: none;
      margin-top: 20px;
      -moz-box-shadow:    2px 4px 9px -1px #000;
      -webkit-box-shadow: 2px 4px 9px -1px #000;
      box-shadow: 2px 4px 9px -1px #000;
      letter-spacing: 1px;
      padding: 17px 18px;
      font-size: 12px;
      &:hover {
        background: #fff;
        color: #d7002b;
        transition: 0.3s ease;
      }
    }
    &.secondary {
      background: #FFFFFF;
      color: #d7002b;
      text-decoration: none;
      float: right;
      transition: 0.3s ease;
      position: absolute;
      right:0;
      -moz-box-shadow:    1px 1px 9px -1px #292929;
      -webkit-box-shadow: 1px 1px 9px -1px #292929;
      box-shadow:         1px 1px 9px -1px #292929;
      font-weight: bold;
      letter-spacing: 1px;
      padding: 16px 9px;
      margin-top: 5px;
      &:hover {
        background: #000;
        color: #FFFFFF;
        transition: 0.3s ease;
        border: 2px solid #d7002b;
      }
    }
    &.icon-book::before {
      content: '';
      background: url(../images/svg-boeken-rood.svg);
      width: 30px;
      height: 24px;
      display: inline-block;
      background-size: cover;
      background-repeat: no-repeat;
      margin: -6px 10px;
    }
    &.uppercase {
      text-transform: uppercase;
    }
    &.lowercase {
      text-transform: lowercase;
    }
  }
  &::after {
    content: '';
    clear: both;
    display: block;
  }
  .readmore {
    margin: 0 0 0 -9px;
    &:hover {
      color: #000;
      transition: 0.3s ease;
      &::after {
        animation-name: fadeOutRight;
        animation-iteration-count: infinite;
        animation-duration: 1s;
        color: #000;
        transition: 0.3s ease;
      }
    }
    &::after {
      content: "\f061";
      display: inline-block;
      font: normal normal normal 14px/1 FontAwesome;
      font-size: inherit;
      text-rendering: auto;
      margin: 0 0 0 5px;
    }
  }
  .showmore {
    background-color: #444444;
    margin-top: 9px;
    margin-left: 5px;
    letter-spacing: 1px;
    font-size: 12px;
    &:hover {
      background: #FFF;
      color: #d7002b;
      transition: 0.3s ease;
      text-decoration: none;
    }
  }
}


/* ==========================================================================
Desktop/iPad PRO
========================================================================== */
@media screen and (max-width: 1200px) {
  .btn-wrapper {
    .secondary {
      padding: 16px 9px;
    }
    .showmore {
      background-color: #444444;
      margin-top: 9px;
      margin-left: 0;
    }
    .book-us {
      padding: 7px 0;
      width: 66px;
      height: 47px;
      .book-us-btn {
        display: none;
        padding: 16px 9px;
      }
    }
  }
}


/* ==========================================================================
Tablet
========================================================================== */
@media screen and (max-width: 992px) {
  .btn-wrapper {
    .dd-btn {
      .secondary {
        padding: 15px 9px;
      }
      .showmore {
        background-color: #444444;
        margin-top: 9px;
        margin-left: 309px;
      }
      .book-us {
        padding: 7px 0;
        width: 66px;
        height: 47px;
        .book-us-btn {
          display: none;
          padding: 14px 9px;
        }
      }
    }
  }
}

/* ==========================================================================
Mobile
========================================================================== */
@media screen and (max-width: 767px) {
  .btn-wrapper {
    .dd-btn.primary {
      background: #d7002b;
      text-decoration: none;
      margin-top: 0;

    }
    .showmore {
      background-color: #444444;
      margin-top: 9px;
      margin-left: 92px;
    }
  }
  #footer {
    .btn-wrapper{
      .dd-btn.secondary {
        background: #FFFFFF;
        color: #d7002b;
        text-decoration: none;
        float: right;
        transition: 0.3s ease;
        position: absolute;
        right: 5px;
        margin-top: 0;
      }
    }
  }
}



