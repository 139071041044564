/* ==========================================================================
General
========================================================================== */
#sidebar{
  padding: 50px 0 0 0;
  margin-bottom: 30px;
  // Sidebar albums styles
  .media-header {
    color: #FFFFFF;
    font-weight: 600;
  }
  .photo-album-link{
    &:hover {
      text-decoration: none;
    }
  }
  .photo-album-thumb-big {
    width: 100%;
    max-height: 100%;
  }
  .photo-album-thumb {
    width:100%;
    max-height: 100%;
  }
  .photo-album-description {
    background-image: url(../images/red-background.jpg);
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 1px 14px;
    color: #fff;
    margin-bottom: 20px;
    a {
      color:white;
      h2 {
        color:#ffffff;
        font-size: 26px;
      }
      &:hover {
        text-decoration: none;
        cursor: pointer;
      }
    }
  }
  // Sidebar form styles
  form {
    h3 {
      color: #fff;
    }
    input {
      background-color: #d7d7d7;
      border: none;
    }
    textarea {
      max-width: 743px;
      background-color: #d7d7d7;
      border: none;

    }
    .btn-primary {
      background-color: #d7002b;
      border: none;
      transition: 0.5s ease;
      &:hover {
        transition: 0.5s ease;
        color: #d7002b;
        background-color: #ffffff;
      }
    }
    ::-webkit-input-placeholder { /* WebKit, Blink, Edge */
      color: #a11723;
    }
    :-moz-placeholder { /* Mozilla Firefox 4 to 18 */
      color: #a11723;
      opacity: 1;
    }
    ::-moz-placeholder { /* Mozilla Firefox 19+ */
      color: #a11723;
      opacity: 1;
    }
    :-ms-input-placeholder { /* Internet Explorer 10-11 */
      color: #a11723;
    }
    ::-ms-input-placeholder { /* Microsoft Edge */
      color: #a11723;
    }
    ::placeholder { /* Most modern browsers support this now. */
      color: #a11723;
    }
  }
  h3 {
    color: #FFF;
    font-size: 32px;
  }
}

/* ==========================================================================
Desktop
========================================================================== */
@media screen and (max-width: 1200px) {

}

/* ==========================================================================
Tablet
========================================================================== */
@media screen and (max-width: 992px) {

}

/* ==========================================================================
Mobile
========================================================================== */
@media screen and (max-width: 767px) {
  #sidebar {
    display: none;
  }
}