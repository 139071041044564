/* ==========================================================================
Breadcrumbs
========================================================================== */
#page-breadcrumbs {
  position: absolute;
  bottom: 0;
  left: 15px;
  right: 15px;
  .container {
    position: relative;
    ul {
      &::before {
        content: '';
        display: block;
        background-image: url('../images/breadcrumb-icon.png');
        height: 20px;
        width: 20px;
        background-size: contain;
        position: absolute;
        left: 0;
      }
      padding: 0 0 0 20px;
      margin: 0;
    }
    li {
      float: left;
      list-style-type: none;
      &:before {
        content: 'U bent hier: ';
        color: #bfbfbf;
        font-size: 14px;
        line-height: 14px;
        margin: 0 10px 0 0;
        text-shadow: 3px 3px 3px #000;
      }
      a {
        text-decoration: none;
        color: #bfbfbf;
        font-size: 14px;
        line-height: 14px;
        text-shadow: 3px 3px 3px #000;
      }
      &::after {
        content: '';
        clear: both;
        display: block;
      }
      &.active {
        > a {
          color: #bfbfbf;
        }
      }
    }
  }
}

.breadcrumb {
  font-size: 14px;
  top: 359px;
  left:330px;
  padding: 5px 0 0 25px;
  position: absolute;
  z-index: 99;
  width: 50%;
  background-color: transparent;
  color: #696464;
  font-weight: bold;
  letter-spacing: 1px;
  ul {
    padding: 0;
    margin: 0;
  }
  li {
    float: left;
    font-size: 15px;
    list-style-type: none;
    line-height: 2.1;

    a {
      text-decoration: none;
      color: #696464;
      &:hover {
        transition: 0.5s ease;
      }
    }
    &+li:before {
      padding: 0 5px;
      font: normal normal normal 14px/1 FontAwesome;
      content: "\f105 ";
      font-size: 12px;
      color: #696464;
    }
    &:after {
      clear:both;
    }
  }
  > .active {
    color: #696464;
  }
}


/* ==========================================================================
Desktop/iPad PRO
========================================================================== */
@media screen and (max-width: 1200px) {}


/* ==========================================================================
Tablet
========================================================================== */
@media screen and (max-width: 992px) {
  .breadcrumb{
    left:0;
  }
  .breadcrumbs{
    left:0;
  }
}



/* ==========================================================================
Mobile
========================================================================== */
@media screen and (max-width: 767px) {
  .breadcrumb {
    display: none;
  }
  .breadcrumbs{
    display: none;
  }
}