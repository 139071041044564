/* ==========================================================================
General
========================================================================== */
#contact{
  .contactform {
    font-family: Roboto, sans-serif;
    background-color: #262626;
    border-radius: 2px;
    padding: 10px 20px 10px 20px ;
    margin-top: 30px;
    h2{
      color: #FFF;
    }
    input{
      background-color: #d7d7d7;
      border: none;
      color: #000;
    }
    textarea{
      max-width: 743px;
      background-color: #d7d7d7;
      border: none;
    }
    .form-field-error-label {
      color: #FFF;
    }
    .btn-primary{
      background-color: #d7002b;
      border: none;
      transition: 0.5s ease;
      &:hover{
        transition: 0.5s ease;
        color:#a11723;
        background-color: #ffffff;
      }
    }
    ::-webkit-input-placeholder { /* WebKit, Blink, Edge */
        color: #a11723;
    }
    :-moz-placeholder { /* Mozilla Firefox 4 to 18 */
      color: #a11723;
      opacity: 1;
    }
    ::-moz-placeholder { /* Mozilla Firefox 19+ */
      color: #a11723;
      opacity: 1;
    }
    :-ms-input-placeholder { /* Internet Explorer 10-11 */
      color: #a11723;
    }
    ::-ms-input-placeholder { /* Microsoft Edge */
      color: #a11723;
    }
    ::placeholder { /* Most modern browsers support this now. */
      color: #a11723;
    }
  }
}


/* ==========================================================================
Desktop
========================================================================== */
@media screen and (max-width: 1200px) {

}

/* ==========================================================================
Tablet
========================================================================== */
@media screen and (max-width: 992px) {

}

/* ==========================================================================
Mobile
========================================================================== */
@media screen and (max-width: 767px) {
  #contact{
    .contactform{
      margin: 65px 0 30px 0;
    }
  }
}
