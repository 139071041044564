/* ==========================================================================
Tablet
========================================================================== */
@media screen and (max-width: 992px) {
  #news-list {
    .img-nieuwsitem {
      height: 400px;
      background-repeat: no-repeat;
      background-size: cover;
      margin-bottom: 30px;
    }
    .dd-acts {
      .dd-acts-body {
        display: none;
        height: 400px;
        position: absolute;
        z-index: 8;
        top: 0;
        width: 350px;
        right: 0;
        animation-name: slideInRight;
        animation-duration: 0.5s;
      }
    }
  }
}

/* ==========================================================================
Mobile
========================================================================== */
@media screen and (max-width: 767px) {
  #news-list {
    .img-nieuwsitem {
      height: 155px;
      background-repeat: no-repeat;
      background-size: cover;
      margin: 0;
    }
    .dd-acts{
      .news-toggle{
        display: none;
      }
      .dd-acts-body {
        animation:none;
        display: block;
        height: 100%;
        position: relative;
        width: 100%;
        margin-bottom: 40px;
        &:after {
          display: block;
        }
      }
    }
  }
}