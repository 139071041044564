/* ==========================================================================
Header
========================================================================== */
#header {
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  background: transparent;
  transition: background 0.7s ease;
  padding: 10px 0;
  background-image: url(../images/bg-nav.jpg);
  height: 400px;
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
}