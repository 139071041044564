#error-page {
  .error-page-container {
    text-align: center;
    h1 {
      font-size: 50px;
    }
    .homepage-btn-wrapper {
      width: 100%;
      margin-top: 44px;
      .homepage-btn {
        float: none;
      }
    }
  }
}



/* ==========================================================================
Mobile
========================================================================== */
@media screen and (max-width: 767px) {
  #error-page {
    .error-page-container {
      .homepage-btn-wrapper {
        margin-top: 30px;
        margin-bottom: 30px;
      }
    }
  }
}