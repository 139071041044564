@import 'buttons';
@import 'editor';
@import 'animations';
@import 'bootstrap';
@import 'textstyle';

@import 'nav';
@import 'header';
@import 'sidebar';
@import 'home';
@import 'possibilitys';
@import 'impression';
@import 'media';
@import 'contact';
@import 'mediaitem';
@import 'reviews';
@import 'bio';
@import '404';
@import 'guestbook';

@import 'news/list';

@import 'footer';
@import 'nieuwsitem';
@import '_socialmedia.scss';
@import '_breadcrumbs';

body {
 background: #000000;
}