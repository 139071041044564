/* ==========================================================================
General
========================================================================== */
#guestbook {
  background-color: #191919;
  iframe {
    margin-top: 49px;
  }
}


/* ==========================================================================
Desktop
========================================================================== */
@media screen and (max-width: 1200px) {

}

/* ==========================================================================
Tablet
========================================================================== */
@media screen and (max-width: 992px) {

}

/* ==========================================================================
Mobile
========================================================================== */
@media screen and (max-width: 767px) {

}