/* ==========================================================================
General
========================================================================== */
h1{
  font-family: Roboto, sans-serif;
  color: #ffffff;
  margin-top: 20px;
}
h2{
  font-family: Roboto, sans-serif;
  color: #d7002b;
  font-weight: 300;
  font-size: 43px;
}
h3{
  font-family: Roboto, sans-serif;
  color: #d7002b;
  font-weight: 400;
}
h4{
  font-family: Roboto, sans-serif;
  color: #ffffff;
}
h5{
  font-family: Roboto, sans-serif;
  color: #ffffff;
}
a{
    font-family: Roboto, sans-serif;
    color: #ffffff;
}
p{
  font-family: Roboto, sans-serif;
  color: #ffffff;
  font-weight: 100;
  font-size: 18px;
}

ul li, ul li a {
  color: #fff;
  font-size: 18px;
}

// Pagination Styles
.pagination{
  .active{
    a{
      border-color: #d7002b;
      background-color: #d7002b;
      &:hover{
        background-color: #9c0000;
        color:white;
        border-color: #d7002b;
      }
      &:focus{
        background-color: #9c0000;
        color:white;
        border-color: #d7002b;
      }
    }
  }
}

.pagination{
  > li {
    > a, span {
      color: #d7002b;
      &:hover{
        color:#d7002b;
      }
      &:focus{
        color:#d7002b;
      }
    }
    &.active > span {
      border-color: #d7002b;
      background-color: #d7002b;
      &:hover {
        border-color: #d7002b;
        background-color: #d7002b;
      }
    }
  }
}
