#impression-page {
  .impression-video-header {
    padding: 18px 18px;
    margin-top: -35px;
  }
  .video-wrapper{
    overflow:hidden;
    position: relative;
  }
  .inactive .video-placeholder {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: auto;
    margin: 0;
    padding: 0;
    z-index: 1;
    transition: 0.5s ease;
    background-image: url('../images/bg-introblok.jpg');
    background-size:cover;
    background-position: center;
    height:100%;
  }
  .active .video-placeholder {
    top: -100%;
    transition: 0.5s ease;
  }
  .impressionlink {
    text-decoration: none;
    color: white;
    position: absolute;
    bottom:20px;
    left:20px;
    z-index: 1;
    .playbutton {
      height: 55px;
      width: 55px;
      &.active{
        display: none;
        content:'';
      }
    }
    span{
      top: 5px;
      position: absolute;
      font-weight: bold;
      text-transform: uppercase;
      padding: 0 0 0 12px;
    }
    &:hover .playbutton{
      animation-name: tada;
      animation-duration: 1.5s;
      animation-iteration-count: 1;
    }
  }
  .videolink {
    text-decoration: none;
    color: white;
    position: relative;
    z-index: 1;
    .playbutton {
      height: 55px;
      width: 55px;
        &.active{
        display: none;
        content:'';
      }
    }
    span{
      top: -8px;
      position: absolute;
      font-weight: bold;
      text-transform: uppercase;
      padding: 0 0 0 12px;
    }
    &:hover .playbutton{
      animation-name: tada;
      animation-duration: 1.5s;
      animation-iteration-count: 1;
    }
  }
  .pausebutton{
    display: none;
    margin-top: 15px;
    &:before{
      border: 4px solid white;
      border-radius: 50%;
      content: "\f04c";
      font-family: FontAwesome;
      font-style: normal;
      font-weight: normal;
      padding: 14px 15px 14px 16px;
      font-size: 20px;
    }
    &.active{
      display: block;
    }
    &:hover{
      animation-name: tada;
      animation-duration: 1.5s;
      animation-iteration-count: 1;
    }
  }
  .impression-video-description {
    padding: 0 55px;
    margin-bottom: 30px;
  }
  .impression-video-link{
    position: relative;
    left: -61%;
    margin-top: -2%;
    margin-bottom: 50px;
    }
    .music-and-video-container {
      background-image: url(../images/bg-videos-and-music.jpg);
      background-repeat: no-repeat;
      background-size: cover;
      padding: 0 15px 0 0;
      border-radius: 10px;
      background-position: center;
    .music-and-video-header {
      padding: 18px 18px;
    }
    .video-container{
      margin-bottom: 30px;
      .video-description {
        background-image: url(../images/red-background.jpg);
        background-color: #d7002b;
        background-position: right;
        background-repeat: no-repeat;
        position: relative;
        min-height: 100px;
        padding: 10px;
        padding-right: 100px;
        margin-top: -5px;
        .video-playbutton {
          position: absolute;
          right: 20px;
          top: 20px;
          z-index: 1;
        }
      }
    }
  }
  .fa {
    position: absolute;
    padding: 10px 10px;
    font-size: 50px;
  }
  .music-container {
    margin-bottom: 30px;
    position: relative;
    .music-description {
      background-image: url(../images/red-background.jpg);
      background-color: #d7002b;
      background-position: right;
      background-repeat: no-repeat;
      position: relative;
      min-height: 100px;
      padding-right: 100px;
      padding: 10px;
      .music-playbutton {
        position: absolute;
        right: 20px;
        top: 20px;
        z-index: 1;
      }
    }
  }
}



/* ==========================================================================
Desktop/iPad PRO
========================================================================== */
@media screen and (max-width: 1200px) {
  #impression-page {
    .videolink {
      text-decoration: none;
      color: white;
      position: relative;
      z-index: 1;
    }
    .impression-video-description {
      text-decoration: none;
      color: white;
      position: relative;
      z-index: 1;
      margin-bottom: 0;
    }
  }
}

/* ==========================================================================
Tablet
========================================================================== */
@media screen and (max-width: 992px) {
  #impression-page {
    .videolink {
      text-decoration: none;
      color: white;
      z-index: 1;
    }
    .music-and-video-header {
      text-align: center;
      padding: 0;
    }
    .impression-video {
      margin-left: 48px;
    }
    .impression-video-header {
      text-align: center;
      margin-top: 0;
    }
    .impression-video-description {
      text-decoration: none;
      color: white;
      position: relative;
      z-index: 1;
      padding: 20px 88px;
      margin-bottom: 0;
      margin-top: 75px;
    }
    .video-container {
      .music-description {
        padding-right: 80px;
        .video-playbutton {
          position: absolute;
          right: 20px;
          z-index: 1;
        }
      }
    }
    .music-container {
    background-image: url(../images/red-background.jpg);
    background-position: top;
    .music-container .music-description .music-playbutton {
      position: relative;
      top: -72px;
      left: 208px;
      z-index: 1;
    }
  }
    .showmore {
      margin-left: 282px;
    }
  }
}



/* ==========================================================================
Mobile
========================================================================== */
@media screen and (max-width: 767px) {
  #impression-page {
    .impression-video {
      width: 100%;
      margin: 0;
    }
    .videolink {
      text-decoration: none;
      color: white;
      position: absolute;
      z-index: 1;
    }
    .impression-video-description {
      text-decoration: none;
      color: white;
      position: relative;
      z-index: 1;
      padding: 20px 18px;
      margin-bottom: 0;
    }
    .video-container {
      margin-left: -3px;
    }
    .music-container {
      margin-left: -2px;
    }
    .showmore {
      margin-left: 37px;
    }
  }
}