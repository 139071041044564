/* ==========================================================================
Responsive Video Embedding
========================================================================== */
.embed-container { position: relative; padding-bottom: 56.25%; height: 0; overflow: hidden; max-width: 100%; height: auto; } .embed-container iframe, .embed-container object, .embed-container embed { position: absolute; top: 0; left: 0; width: 100%; height: 100%; }

/* ==========================================================================
Homepage
========================================================================== */
#home {
  background-image: url('../images/bg-header.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  background-position-x: 300px;
  #intro {
    padding: 100px 0 0 0;
    #video-panel-container {
      -webkit-box-shadow: -48px -61px 300px -29px rgba(255,255,255,1);
      -moz-box-shadow: -48px -61px 300px -29px rgba(255,255,255,1);
      box-shadow: -48px -61px 300px -29px rgb(66, 66, 66);
      #dd-video #dd-video-panel {
        background-color: #d7002b;
        padding: 38px;
        color: #FFF;
        font-size: 17px;
        .btn-wrapper {
          margin: 0;
          .dd-btn {
            margin: 0 0 0 -9px;
          }
        }
      }
      .video-panel-container-shadow {
        -webkit-box-shadow: -26px -1px 27px -18px rgba(0,0,0,0.64);
        -moz-box-shadow: -26px -1px 27px -18px rgba(0,0,0,0.64);
        box-shadow: -26px -1px 27px -18px rgba(0,0,0,0.64);
        .video-wrapper {
          position: relative;
          overflow: hidden;
          &.inactive .video-placeholder {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: auto;
            margin: 0;
            padding: 0;
            z-index: 1;
            transition: 0.5s ease;
            background-image: url('../images/bg-introblok.jpg');
            background-size:cover;
            background-position: center;
            height:100%;
          }
          &.active .video-placeholder {
            top: -100%;
            transition: 0.5s ease;
          }
          .video-placeholder {
            .videolink {
              text-decoration: none;
              color: white;
              position: absolute;
              bottom: 20px;
              left: 20px;
              z-index: 20;
              .playbutton {
                height: 55px;
                width: 55px;
              }
              span{
                top:5px;
                position: absolute;
                font-weight: bold;
                text-transform: uppercase;
                padding: 0 0 0 12px;
                text-shadow: 1px 1px #000;
              }
              &:hover .playbutton{
                animation-name: tada;
                animation-duration: 1.5s;
                animation-iteration-count: 1;
              }
            }
          }
        }
      }
    }
    #testimonialSwiper {
      margin: 10px 0;
      &.swiper-container {
        padding: 17px 0 36px 0;
        .swiper-wrapper .swiper-slide {
          font-style: italic;
          .editor.grey.light-font p {
            font-size: 25px;
            font-weight: 200;
            font-family: Roboto, sans-serif;
            text-align: center;
            color: #FFFFFF;

          }
        }
        .swiper-pagination-bullet {
          background-color: #acadaf;
          width: 11px;
          height: 11px;
        }
        .swiper-pagination-bullet-active {
          background-color: #d7002b;
        }
      }
    }
  }
}

/* ==========================================================================
Impression
========================================================================== */
#impression {
  .dd-acts {
    position: relative;
    margin-bottom: 50px;
    .img-nieuwsitem {
      height: 200px;
      background-repeat: no-repeat;
      background-size: cover;
    }
    .dd-acts-body {
      display: block;
      padding: 1px 19px;
      background-image: url('../images/red-background.jpg');
      background-size: cover;
      .editor {
        margin: 0;
        h3 {
          font-size: 24px;
        }
      }
      .btn-wrapper {
        margin: 0;
        a {
          text-decoration: none;
        }
      }
      &:after{
        display: block;
      }
    }
    .news-toggle {
      display: none;
      right: 0;
      top: 0;
      bottom: 0;
      height: 100%;
      position: absolute;
      background: #d7002b;
      width: 50px;
      text-decoration: none;
      z-index: 10;
      .fa {
        color: #fff;
        display: block;
        text-align: center;
        height: 50px;
        width: 50px;
        position: absolute;
        right: 0;
        top: 50%;
        margin-top: -25px;
        line-height: 50px;
        font-size: 32px;
        transform: rotate(0deg);
        transition: transform .3s ease;
      }
      &.active {
        .fa {
          transform: rotate(-180deg);
          transition: .3s ease;
        }
      }
    }
  }




  .dd-contact-left-column {
    direction: rtl;
    padding: 0 36px;
  }
  .dd-contact-center-column {
    padding: 16px 36px;
  }
  .dd-contact-right-column {
    padding: 16px 36px;
  }
  .dd-contact-phone-img {
    padding: 7px;
    background-color: #FFFFFF;
    -webkit-filter: invert(100%);
    filter: invert(100%);
    width: 60px;
    float: left;
  }
  .dd-book {
    padding: 6px;
    font-size: 24px;
  }
  .book-us-online{
    background-color: #d7002b;
    text-align: center;
    border-radius: 50px;
    padding-top: 1px;
  }
  .dd-row-contact {
    margin-top: 50px;
  }
  .dd-phone-icon{
    width:80px;
  }
  .right-border {
    border-right: 1px solid #FFFFFF;
    height: 105px;
  }

  .bg-impression {
    background-image: url(../images/bg-overons.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position-x: -88px;
    background-position-y: -33px;
    padding-bottom: 755px;
    .impression-photos {
      display: inline-flex;
      .photo-impression-container-shadow{
        -webkit-box-shadow: 5px 6px 16px 2px rgba(0,0,0,0.64);
        -moz-box-shadow: 5px 6px 16px 2px rgba(0,0,0,0.64);
        box-shadow: 5px 6px 16px 2px rgba(0,0,0,0.64);
      }
      .img-border {
        border: 12px solid #FFF;
        margin-bottom: 10px;
        .impression-img {
          transition: 0.3s ease;
          &:hover {
            background-color: #d7002b;
            cursor: pointer;
            transform: scale(1.15);
          }
        }
      }
    }
  }

}

/* ==========================================================================
Desktop/iPad Pro
========================================================================== */
@media screen and (max-width: 1200px) {
  .bg-home {
    background-image: url(../images/bg-header-tablet.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
    background-position-y: -54px;
  }
  #intro {
    .video-panel-container {
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;
    }
  }
  #impression {
    .bg-impression {
      background-image: url(../images/bg-overons.jpg);
      background-size: cover;
      background-repeat: no-repeat;
      background-position-x: -199px;
      background-position-y: -33px;
      padding-bottom: 0;
      .impression-photos {
        display: unset;
      }
    }
  }
  .videolink {
    text-decoration: none;
    color: white;
    position: absolute;
    bottom: 60%;
  }
}

/* ==========================================================================
Tablet
========================================================================== */
@media screen and (max-width: 992px) {
  #home {
    background-position-x: 0;
    .bg-home {
      background-image: url(../images/bg-header-tablet.jpg);
      background-size: auto;
      background-repeat: no-repeat;
      background-position: top;
      background-position-x: -93px;
    }
    #intro {
      .intro {
        width: 400px;
      }
      .video-panel-container {
        padding: 20px;
      }
    }
  }
  #impression {
    background-image: url(../images/bg-overons.jpg);
    background-size: contain;
    background-repeat: no-repeat;
    background-position-x: 24px;
    background-position-y: 0px;

    #news-list {
      .dd-acts {
        overflow: hidden;
        .img-nieuwsitem {
          height: 300px;
          margin: 0;
        }
        .dd-acts-body {
          position: absolute;
          top: 0;
          bottom: 0;
          right: -100%;
          transition: .5s ease;
          z-index: 3;
          height: 100%;
          display: block;
          padding: 30px 70px 30px 30px;
          .editor {
            h3 {
              font-size: 24px;
            }
          }
        }
        &.show {
          .dd-acts-body {
            right: 0;
            transition: .5s ease;
            left: auto;
          }
        }
        .news-toggle {
          display: block;
        }
      }
    }
    .bg-impression {
      background-image: none;
      .impression-photos {
        display: inline-flex;
        .img-border {
          margin-left: 31px;
        }
      }
      .impression-img {
        display: inline-flex;
      }
      .right-border {
        margin-right: 53px;
      }
      .photo-impression-container {
        padding-top: 0;
      }
    }
  }
}

/* ==========================================================================
Mobile
========================================================================== */
@media screen and (max-width: 767px) {
  #home {
    background-position-x: -200px;
    background-position-y: 40px;
    .bg-home {
      background-image: none;
    }
    #intro {
      .intro {
        width: 290px;
        margin-top: -44px;
        h1, h2, p {
          width: 290px;
        }
      }
      .video-panel-container {
        margin-top: -26px;
      }
      .videolink {
        bottom: 7px;
        left: 1px;
        span {
          font-size: 12px;
        }
        .playbutton {
          height: 42px;
        }
      }
      #dd-video-panel {
        padding: 12px;
      }
      #testimonialSwiper {
        .swiper-slide {
          font-size: 24px;
        }
      }
    }
  }
  #impression {
    .bg-impression {
      padding: 0;
      .editor {
        padding: 0;
        margin: 0;
      }
      .photo-impression-container {
        margin: 0;
        padding: 0;
        .impression-photos {
          display: unset;
          .img-border {
            margin-bottom: 17px;
            margin-left: 10px;
          }
        }
      }
    }
  }
}
