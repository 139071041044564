#media {
  background-image: url(../images/bg-media.jpg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  background-position-y: -1px;
  .album {
    .preview {
      background-size: cover;
      background-repeat: no-repeat;
      height: 140px;
    }
    &.big {
      .preview {
        height: 400px;
      }
    }
    .description {
      background-image: url(../images/red-background.jpg);
      background-position: top;
      background-repeat: no-repeat;
      background-size: cover;
      padding: 1px 14px;
      color: #fff;
      margin-bottom: 20px;
      height: 100px;
      position: relative;
      h2 {
        font-size: 26px;
        color: #fff;
      }
      a {
        position: absolute;
        text-decoration: none;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
      }
    }
  }
}



/* ==========================================================================
Desktop/iPad PRO
========================================================================== */
@media screen and (max-width: 1200px) {

}

/* ==========================================================================
Tablet
========================================================================== */
@media screen and (max-width: 992px) {
  #media .album .preview {
    height: 400px;
  }
}

/* ==========================================================================
Mobile
========================================================================== */
@media screen and (max-width: 767px) {

}