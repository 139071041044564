/* ==========================================================================
General
========================================================================== */
#reviews {
  iframe {
    margin-top: 49px;
  }
  .singlereview {
    padding: 30px 0 0 0;
    border-bottom: 2px solid #2b2a2a;
    h3 {
      font-size: 40px;
    }
    h2 {
      margin: 0;
      font-size: 43px;
    }
    span {
      font-size: 14px;
      color: #ffff;
    }
  }
}

/* ==========================================================================
Desktop
========================================================================== */
@media screen and (max-width: 1200px) {

}

/* ==========================================================================
Tablet
========================================================================== */
@media screen and (max-width: 992px) {

}

/* ==========================================================================
Mobile
========================================================================== */
@media screen and (max-width: 767px) {

}