/* ==========================================================================
General
========================================================================== */
#nieuwsitem{
  font-family: Roboto, sans-serif;
  padding: 0 0 0 0;
  margin-top: 2%;
  h2{
    color: #d7002b;
    font-size: 36px;
    text-align: center;
  }
  p{
    color: #ffffff;
    font-size: 22px;
  }
  .header-photo {
    width: 70%;
    text-align: center;
    margin: auto;
    img {
      margin: 0 auto;
      border: 5px solid #696468;
    }
  }
}

/* ==========================================================================
Desktop
========================================================================== */
@media screen and (max-width: 1200px) {
  .breadcrumbs {
    left: 19px;
  }
}

/* ==========================================================================
Tablet
========================================================================== */
@media screen and (max-width: 992px) {
  .breadcrumbs {
    display: none;
  }
}

/* ==========================================================================
Mobile
========================================================================== */
@media screen and (max-width: 767px) {
  .breadcrumbs {
    display: none;
  }
  #nieuwsitem {
    font-family: Roboto, sans-serif;
    padding: 0 0 0 0;
    margin-top: 20%;
  }
}
