/* ==========================================================================
General
========================================================================== */
#mediaitem{
  font-family: Roboto, sans-serif;
  padding: 50px 0 200px 0;
  .albumintro{
    margin:  0 0 20px 0;
    h2{
      color:white;
    }
    p{
      color:white;
    }
  }
  .albumrow{
    background-color: rgba(107, 107, 107, 0.1);
    border-bottom: 2px solid rgba(229, 229, 229, 0.5);
    .albumitem{
      height: 250px;
      background-repeat: no-repeat;
      background-size: cover;
      margin: 30px 0;
      position: relative;
      a {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
      }
    }
  }
}

/* ==========================================================================
Desktop
========================================================================== */
@media screen and (max-width: 1200px) {

}

/* ==========================================================================
Tablet
========================================================================== */
@media screen and (max-width: 992px) {

}

/* ==========================================================================
Mobile
========================================================================== */
@media screen and (max-width: 767px) {


}