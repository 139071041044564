/* ==========================================================================
General
========================================================================== */
#socialmedia{
  position: absolute;
  margin: 555px 0 0 0;
  ul {
    animation-name: slideInLeft;
    animation-duration: 2s;
    animation-iteration-count: 1;
    list-style: none;
    padding: 0;
    margin: 0;
    li {
      .socialbutton {
        background-color:  #d7002b;
        width:40px;
        height:40px;
        text-align: center;
        padding: 10px;
        a {
          color: #d7d7d7;
          z-index: 999;
          &:after {
            clear: both;
          }
        }
        &:hover .fa{
          transition: 0.5s ease;
          color: black;
        }
      }
      #facebook {
        border-bottom: 2px solid rgba(251, 251, 251, 0.3);
      }
    }
  }
}

/* ==========================================================================
Desktop
========================================================================== */
@media screen and (max-width: 1200px) {
  .wrapper .btn-wrapper .dd-btn {
    margin-right: 10px;
    margin-top: 1px;
  }
}

/* ==========================================================================
Tablet
========================================================================== */
@media screen and (max-width: 992px) {
  #socialmedia{
    display: none;
  }
}

/* ==========================================================================
Mobile
========================================================================== */
@media screen and (max-width: 767px) {

}
